import ChatForm from './chat_form';

export default class ChatWelcome {
  constructor(opts) {
    this.$wrapper = opts.$wrapper;
    this.profile = opts.profile;
    this.setup();
  }

  setup() {
    this.$chat_welcome_screen = $(document.createElement('div')).addClass(
      'chat-welcome'
    );

    const welcome_html = `
			<div class='chat-welcome-header'>
					<span class='hero-icon'>
            <i class="fas fa-comment"></i>
					</span>
					<h3>${__('Hi there ! 🙌🏼')}</h3>
					<p>
						${__('We make it simple to connect with us.')}
						${__('Ask us anything, or share your feedback.')}
					</p>
			</div>
		`;

    const status_text = __('Send us a message!');

    const reason_text = __('Write to us and we will get back to you soon');

    const bottom_html = `
			<div class='chat-welcome-footer'>
				<p class='status-content'>${status_text}</p>
				<p class='hero-content'>${reason_text}</p>
				<button type='button' class='btn btn-primary w-100'
					id='start-conversation'>
					${__('Contact Us')}
				</button>
				<a class='chat-footer welcome-footer' target='_blank' href='https://frontech.ca/'>
					${__('Powered by Frontech')}
				</a>
			</div>
		`;

    this.$chat_welcome_screen.append(welcome_html + bottom_html);
  }

  setup_events() {
    const me = this;
    $('#start-conversation').on('click', function () {
      me.chat_form = new ChatForm({
        $wrapper: me.$wrapper,
        profile: me.profile,
      });
      me.chat_form.render();
    });
  }

  render() {
    this.$wrapper.html(this.$chat_welcome_screen);
    this.setup_events();
  }
}
